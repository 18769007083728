import { Flex } from '@chakra-ui/react';
import type { ReactNode } from 'react';

import PageHeader from '@lib/components/structure/PageHeader';
import { ImageBackground } from '@lib/images';

import Footer from './Footer';
import HeaderMinimal from './HeaderMinimal';

type LayoutProps = {
  title?: string;
  subtitle?: ReactNode;
  children: ReactNode;
};

const LayoutPublic = ({ title, subtitle, children }: LayoutProps) => {
  return (
    <Flex margin="0 auto" transition="0.5s ease-out" flexDirection="column">
      <Flex
        direction="column"
        justifyContent={{
          base: 'flex-start',
        }}
        backgroundImage={{
          base: `url(${ImageBackground.src})`,
        }}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition={{
          base: 'top center',
        }}
        backgroundColor="#6354c3"
        h="100vh"
        w="full"
      >
        <HeaderMinimal />
        <Flex
          direction="column"
          alignItems="center"
          textAlign="center"
          height="100%"
        >
          {title && (
            <Flex flexDirection="column" mt={5}>
              <PageHeader text={title} />
            </Flex>
          )}
          {subtitle}
          {children}
        </Flex>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default LayoutPublic;
