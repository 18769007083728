/* eslint-disable @next/next/no-img-element */
import { Flex } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';

import ConnectWallet from '@lib/components/ConnectWallet';
import Header from '@lib/components/text/Header';
import { ImageBackground, ImageHeeDongGroup } from '@lib/images';
import LayoutPublic from '@lib/layout/Public';

const Home = () => {
  const router = useRouter();
  const { isConnected } = useAccount();
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (isConnected) {
      router.push('/grow');
    }
    /* else if (isConnected && !isSignedIn) {
      signIn();
    } */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  if (!hasMounted) return null;

  return (
    <LayoutPublic>
      <NextSeo title="Home" />

      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        w="full"
        h="full"
        pt={[10, 12, 16]}
      >
        <Flex flexDirection="column" zIndex={1}>
          <Header>
            Ready to Join
            <br />
            the Wonderful
            <br />
            World of
            <br />
          </Header>
          <Header size="4xl" color="#FFC500">
            HeeDong
          </Header>
        </Flex>
        <Flex
          width="100%"
          height={['60vh', '50vh', '470px']}
          mt={-10}
          mb={8}
          justifyContent="center"
          backgroundImage={ImageHeeDongGroup.src}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
        />
      </Flex>
      <Flex
        width="100%"
        justifyContent="center"
        position="fixed"
        bottom="30px"
        left="0"
      >
        <ConnectWallet />
      </Flex>
    </LayoutPublic>
  );
};

export default Home;
