import ImageHeartEyes from '~/assets/images/HD_Heart Eyes.png';
import ImageBackground from '~/assets/images/heedong/background.jpg';
import ImageBlindBox from '~/assets/images/heedong/blindbox.gif';
import ImageHeeDongBlack from '~/assets/images/heedong/heedong-black.png';
import ImageHeeDongEating from '~/assets/images/heedong/heedong-eating.png';
import ImageHeeDongGroup from '~/assets/images/heedong/heedong-group.png';
import ImageHeeDongSleepy from '~/assets/images/heedong/heedong-sleepy.png';
import ImageLoadingBaby from '~/assets/images/heedong/loading-baby.gif';
import ImageLoadingLogo from '~/assets/images/heedong/loading-logo.gif';
import ImageLogo from '~/assets/images/heedong/logo.png';
import ImageStar from '~/assets/images/heedong/star.png';
import ImageNotABotLogo from '~/assets/images/notabot.png';

export {
  ImageBackground,
  ImageHeeDongBlack,
  ImageHeeDongGroup,
  ImageBlindBox,
  ImageLogo,
  ImageLoadingBaby,
  ImageLoadingLogo,
  ImageHeeDongSleepy,
  ImageHeeDongEating,
  ImageHeartEyes,
  ImageStar,
  ImageNotABotLogo,
};
