import { Heading, Spinner } from '@chakra-ui/react';
import { ConnectKitButton } from 'connectkit';
import { useAccount } from 'wagmi';

import PrimaryButton from './buttons/PrimaryButton';

const ConnectWallet = () => {
  const { isConnected, isConnecting } = useAccount();

  return (
    <ConnectKitButton.Custom>
      {({ show }) => {
        return (
          <PrimaryButton
            onClick={!isConnecting ? show : () => {}}
            minWidth="250px"
          >
            {!isConnected && (
              <Heading
                size={{ base: 'sm', md: 'lg' }}
                letterSpacing={1}
                fontWeight="900"
              >
                {isConnecting ? 'CONNECTING...' : 'CONNECT WALLET'}
              </Heading>
            )}
            {isConnected && <Spinner size="lg" />}
          </PrimaryButton>
        );
      }}
    </ConnectKitButton.Custom>
  );
};

export default ConnectWallet;
