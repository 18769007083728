import { Button } from '@chakra-ui/react';
import type { ReactNode } from 'react';

type Props = {
  disabled?: boolean;
  onClick?: (() => void) | undefined;
  children: ReactNode;
  [key: string]: unknown;
};

const PrimaryButton = ({
  onClick,
  disabled = false,
  children,
  ...rest
}: Props) => {
  const backgroundColor = disabled ? '#D0D3D4' : '#fbcb14';
  const textColor = disabled ? '#34495E' : 'black';

  return (
    <Button
      onClick={disabled ? () => {} : onClick}
      _hover={{ backgroundColor }}
      _active={{
        backgroundColor,
        transform: disabled ? 'none' : 'translate3d(3px, 3px, 3px)',
        boxShadow: disabled ? '4px 4px #000' : 'none',
      }}
      textTransform="uppercase"
      fontWeight="bold"
      cursor={disabled ? 'not-allowed' : 'pointer'}
      boxShadow={{ base: '2px 2px #000', md: '4px 4px #000' }}
      borderColor="black"
      borderWidth={2}
      borderRadius={22}
      backgroundColor={backgroundColor}
      color={textColor}
      py={{
        base: 3,
        md: 6,
      }}
      px={{
        base: 5,
        md: 8,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
