/* eslint-disable @next/next/no-img-element */
import { Flex } from '@chakra-ui/react';

import { ImageLogo } from '@lib/images';

const HeaderMinimal = () => {
  return (
    <Flex
      as="header"
      width="full"
      align="center"
      justifyContent="center"
      pt={8}
      pb={2}
    >
      <Flex height={9} width="full" justifyContent="center">
        <img
          src={ImageLogo.src}
          alt="heedong logo"
          style={{ display: 'flex' }}
        />
      </Flex>
    </Flex>
  );
};

export default HeaderMinimal;
