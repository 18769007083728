import { Heading } from '@chakra-ui/react';
import type { ReactNode } from 'react';

type Props = { children: ReactNode; [x: string]: unknown };

const Header = ({ children, ...rest }: Props) => {
  let letterSpacing = 1;
  let textShadow: any =
    '0px -1px 0 #000, 1px -1px 0 #000, 1px 1px 0 #000, 1px 0px 0 #000, 0px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 0px 4px 0 #000, 1px 4px 0 #000, -1px 4px 0 #000';

  switch (rest.size) {
    case 'xs':
      letterSpacing = 2;
      textShadow =
        '0px -1px 0 #000, 1px -1px 0 #000, 1px 1px 0 #000, 1px 0px 0 #000, 0px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 0px 2px 0 #000, 1px 2px 0 #000, -1px 2px 0 #000';
      break;

    case 'sm':
      letterSpacing = 2;
      textShadow =
        '0px -1px 0 #000, 1px -1px 0 #000, 1px 1px 0 #000, 1px 0px 0 #000, 0px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 0px 3px 0 #000, 1px 3px 0 #000, -1px 3px 0 #000';
      break;

    case 'md':
    case 'lg':
      letterSpacing = 3;
      textShadow = {
        md: '0px -1px 0 #000, 1px -1px 0 #000, 1px 1px 0 #000, 1px 0px 0 #000, 0px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 0px 5px 0 #000, 1px 5px 0 #000, -1px 5px 0 #000',
        base: '0px -1px 0 #000, 1px -1px 0 #000, 1px 1px 0 #000, 1px 0px 0 #000, 0px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 0px 3px 0 #000, 1px 3px 0 #000, -1px 3px 0 #000',
      };
      break;

    default:
      break;
  }

  return (
    <Heading
      textTransform="uppercase"
      fontWeight="900"
      size="2xl"
      letterSpacing={letterSpacing}
      lineHeight={1.2}
      textShadow={textShadow}
      zIndex={1}
      {...rest}
    >
      {children}
    </Heading>
  );
};

export default Header;
